import { Link } from "gatsby"
import React, { useState } from "react"
import useFooter from "~/hooks/useFooter"
// import useOptions from "~/hooks/useOptions"
import FooterTitle from "~/images/footerTitle.svg"
import useLegal from "~/hooks/useLegal"
import DefaultPattern from "./patterns/DefaultPattern"

function Footer() {
  const {
    footerNav: { menuItems },
  } = useFooter()

  // const {
  //   footer: { logo, socials, topText },
  // } = useOptions()

  // const { nodes } = useLegal()

  const [showLegal, setShowLegal] = useState(false)

  return (
    <footer className="relative py-20 bg-black md:py-36 ">
      <div className="container relative">
        <div className="flex flex-wrap">
          <div className="md:min-w-[420px]">
            <img
              src={FooterTitle}
              alt="footer title"
              className="object-contain object-top xl:mr-[7.563rem] mx-auto lg:mr-auto lg:ml-0 mb-12 lg:mb-0"
            />

            <div className="flex mt-6 -ml-1 md:mt-16">
              <a
                href="https://twitter.com/futureplc"
                target="_blank"
                className="pl-1 transition-opacity duration-200 ease-in-out hover:opacity-60"
              >
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.4119 13.6468C24.7766 13.9645 24.1413 14.0704 23.4001 14.1762C24.1413 13.7527 24.6707 13.1174 24.8825 12.2704C24.2472 12.6939 23.506 12.9057 22.6589 13.1174C22.0236 12.4821 21.0707 12.0586 20.1178 12.0586C17.8942 12.0586 16.2001 14.1762 16.7295 16.2939C13.8707 16.188 11.3295 14.8115 9.52953 12.6939C8.57659 14.2821 9.106 16.2939 10.5883 17.3527C10.0589 17.3527 9.52953 17.1409 9.00011 16.9292C9.00011 18.5174 10.1648 19.9998 11.7531 20.4233C11.2236 20.5292 10.6942 20.6351 10.1648 20.5292C10.5884 21.9057 11.8589 22.9645 13.4472 22.9645C12.1766 23.9174 10.2707 24.4468 8.4707 24.2351C10.0589 25.188 11.8589 25.8233 13.7648 25.8233C20.2236 25.8233 23.8236 20.4233 23.6119 15.4468C24.3531 15.0233 24.9883 14.388 25.4119 13.6468Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/future-publishing/"
                target="_blank"
                className="pl-1 transition-opacity duration-200 ease-in-out hover:opacity-60"
              >
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3416 25.4119H8.74155V14.0825H12.3416V25.4119ZM10.5416 12.4942C9.37685 12.4942 8.52979 11.6472 8.52979 10.4825C8.52979 9.31776 9.48273 8.4707 10.5416 8.4707C11.7063 8.4707 12.5533 9.31776 12.5533 10.4825C12.5533 11.6472 11.7063 12.4942 10.5416 12.4942ZM25.471 25.4119H21.871V19.2707C21.871 17.4707 21.1298 16.9413 20.071 16.9413C19.0121 16.9413 17.9533 17.7883 17.9533 19.3766V25.4119H14.3533V14.0825H17.7415V15.6707C18.0592 14.9295 19.3298 13.7648 21.1298 13.7648C23.1415 13.7648 25.2592 14.9295 25.2592 18.4236V25.4119H25.471Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>

          <nav className="flex flex-wrap justify-between mt-12 md:flex-nowrap lg:flex-1 lg:mx-0 lg:mt-0 xl:mr-14 gap-y-5">
            {menuItems?.nodes?.map((navItemGroup, i, arr) => (
              <div
                key={`footerNavGroup${i}`}
                className={` flex flex-col ${
                  i !== arr.length - 1 &&
                  "mr-12 md:mr-24 xl:mr-[1.438rem] mb-5 md:mb-0"
                }`}
              >
                <div className="text-14 text-white font-extrabold leading-[1.27] uppercase mb-5">
                  {navItemGroup?.label}
                </div>
                {navItemGroup?.childItems?.nodes?.map((navItem, i) => (
                  <Link
                    key={`footerChildItem${i}`}
                    className=" mb-2 text-15 text-white duration-200 opacity-60 leading-[1.604] transition-all hover:underline hover:opacity-100 ease-in-out"
                    to={navItem?.path}
                  >
                    {navItem?.label}
                  </Link>
                ))}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </footer>
  )
}

export default Footer
