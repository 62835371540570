import { motion, useTransform, useViewportScroll } from "framer-motion"
import React from "react"

export default function ScrollProgress() {
  const { scrollYProgress } = useViewportScroll()
  const pathLength = useTransform(scrollYProgress, [0, 1], [0, 1])
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 360])

  const scrollToTop = () => {
    window && window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <div onClick={scrollToTop} className="cursor-pointer mix-blend-difference">
      <svg
        width="47"
        height="47"
        viewBox="0 0 47 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_237:7)">
          <motion.path
            style={{ rotate }}
            d="M23.499 46.2295C36.0522 46.2295 46.2285 36.0532 46.2285 23.5C46.2285 10.9469 36.0522 0.770508 23.499 0.770508C10.9459 0.770508 0.769531 10.9469 0.769531 23.5C0.769531 36.0532 10.9459 46.2295 23.499 46.2295Z"
            stroke="currentColor"
            strokeOpacity="0.1"
            stroke-width="1.54098"
          />
          <path
            d="M24.6051 21.5379L24.2587 21.2052L23.9123 21.5379L18.4673 26.7685L17.7074 25.9787L23.8773 20.0556L23.8783 20.0547L24.2587 19.6914L24.6392 20.0547L24.6402 20.0556L30.81 25.9787L30.0502 26.7685L24.6051 21.5379Z"
            fill="currentColor"
            className="mix-blend-difference"
            stroke="currentColor"
          />
          <motion.path
            style={{ pathLength, rotate: rotate }}
            d="M23.7295 46.459C36.2827 46.459 46.459 36.2827 46.459 23.7295C46.459 11.1763 36.2827 1 23.7295 1C11.1763 1 1 11.1763 1 23.7295C1 36.2827 11.1763 46.459 23.7295 46.459Z"
            stroke="currentColor"
            strokeWidth="1.54098"
          />
        </g>
        <defs>
          <clipPath id="clip0_237:7">
            <rect width="47" height="47" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
