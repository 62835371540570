import * as React from "react"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import useSiteInfoAssets from "~/hooks/useSiteInfoAssets"
import Header from "~/components/Header"
import Footer from "~/components/Footer"
import ScrollProgress from "~/components/animations/ScrollProgress"

const Layout = ({
  children,
  dark,
  headerBg,
  hideCTA = false,
  noise = true,
  showPattern = false,
  patternOpacity = 0.6,
  className = "",
}) => {
  const seo = useSiteInfoAssets();

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <Header
        dark={dark}
        headerBg={headerBg}
        noise={noise}
        showPattern={showPattern}
        patternOpacity={patternOpacity}
      />

      <div className="fixed bottom-[7rem] right-[3rem] z-50 hidden md:block mix-blend-difference text-white">
        <ScrollProgress />
      </div>

      <main className={`${className}`}>{children}</main>

      <Footer />
    </SEOContext.Provider>
  )
}

export default Layout
