import { Link } from "gatsby"
import React, { useState } from "react"
import { motion } from "framer-motion"

function SubnavItem({
  path,
  label,
  description,
  i,
  className = "",
  disableHover = false,
}) {
  const [onHover, setOnHover] = useState(false)

  let delay = (i + 1) / 12

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
      transition={{ duration: 0.4, delay: delay, ease: "easeIn" }}
      className={`${className}`}
    >
      <Link
        onMouseEnter={() => setOnHover(disableHover ? false : true)}
        onMouseLeave={() => setOnHover(false)}
        to={path || "#"}
      >
        <motion.div
          initial={{
            width: "30%",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          }}
          animate={{
            width: onHover ? "100%" : "30%",
            backgroundColor: onHover
              ? "rgba(255, 255, 255, 1)"
              : "rgba(255, 255, 255, 0.2)",
          }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          className="h-[7px] "
        ></motion.div>
        <div className="mt-5 font-extrabold text-white uppercase u-p2 text-18">
          {label}
        </div>
        <div className="mt-5 u-p2 text-12 xl:text-14 font-medium leading-[1.604] text-white ">
          {description}
        </div>
      </Link>
    </motion.div>
  )
}

export default SubnavItem
