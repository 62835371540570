import { graphql, useStaticQuery } from "gatsby"

const useMobileNav = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileNav: wpMenu(locations: { eq: MOBILE_NAV }) {
        name
        id
        menuItems {
          nodes {
            parentId
            url
            label
            id
            childItems {
              nodes {
                description
                url
                id
                label
                path
                parent {
                  ... on WpMenu {
                    id
                    name
                  }
                }
                childItems {
                  nodes {
                    id
                    label
                    path
                    description
                  }
                }
              }
            }
            path
          }
        }
      }
    }
  `)

  // Only get top level items
  data.mobileNav.menuItems.nodes = data.mobileNav.menuItems.nodes.filter(
    n => !n.parentId
  )

  return data
}

export default useMobileNav
