import React, { useEffect, useState } from "react"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"
import Image from "./Image"
import PlayIcon from "~/images/play-icon.svg"
import AnimatedWebcast from "./animations/AnimatedWebcast"

const VideoModal = ({
  previewImage,
  videoEmbed,
  className = "",
  imgClass = "",
  imageClass = "",
  iconClass = "",
  playBtnClass = "bg-red text-white",
  textClass = "text-white",
  button = false,
  playButton = false,
  blogPlay,
  caption,
  customVideo,
  title,
  iconButton,
  channel = "youtube",
}) => {
  const [isOpen, setOpen] = useState(false)
  const [videoID, setVideoID] = useState(false)

  useEffect(() => {
    if (!videoEmbed) return
    let videoSplit
    if (videoEmbed.includes("v=")) {
      videoSplit = videoEmbed.split("v=")
    } else if (videoEmbed.includes("/embed/")) {
      videoSplit = videoEmbed.split("/embed/")
    }

    let videoID
    if (videoSplit && videoSplit?.length >= 2) {
      videoID = videoSplit[1]
      const ampersandPosition = videoID.indexOf("&")
      if (ampersandPosition !== -1) {
        videoID = videoID.substring(0, ampersandPosition)
      }
    }

    setVideoID(videoID)
  }, [videoEmbed])

  return (
    <>
      {button ? (
        <VideoButton setOpen={setOpen} title={title} />
      ) : iconButton ? (
        <AnimatedWebcast onClick={() => setOpen(true)} />
      ) : playButton ? (
        <PlayButton onClick={() => setOpen(true)} />
      ) : (
        <div className={`${className} overflow-hidden`}>
          <div className={`relative  aspect-h-3 md:aspect-h-2 ${imgClass}`}>
            <Image
              image={previewImage}
              objectFit={"cover"}
              className={imageClass}
            />
            <div
              className="absolute inset-0 flex items-center justify-center cursor-pointer group"
              onClick={() => setOpen(true)}
            >
              {blogPlay ? (
                <img
                  src={blogPlay}
                  draggable={false}
                  className={`transition-transform duration-150 transform group-hover:scale-105 ${iconClass} `}
                />
              ) : (
                <div className="relative w-24 h-24 md:w-40 md:h-40">
                  <div
                    className={`w-full h-full transition-transform duration-300 ease-in-out transform rounded-full  group-hover:scale-110 ${playBtnClass} `}
                  ></div>
                  <div className="absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    <span className={` u-h5 text-18 ${textClass} `}>Play</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {caption && <div className="mt-3 opacity-40">{caption}</div>}
        </div>
      )}
      <ModalVideo
        channel={channel}
        autoplay={1}
        isOpen={isOpen}
        videoId={videoID}
        url={customVideo}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default VideoModal

export const VideoButton = ({ setOpen, title }) => (
  <div
    className="inline-block border border-white rounded-[30px] w-full md:max-w-max cursor-pointer bg-transparent hover:border-red hover:bg-red transition-colors duration-200"
    onClick={() => setOpen(true)}
  >
    <div className="flex items-center justify-center  py-[18px] md:px-[31px] md:py-[23px]">
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.53 5.15258L1.53 0.152578C1.221 -0.0414217 0.833 -0.0504216 0.515 0.125578C0.197 0.302578 0 0.636578 0 1.00058V11.0006C0 11.3646 0.197 11.6986 0.515 11.8756C0.667 11.9586 0.833 12.0006 1 12.0006C1.184 12.0006 1.368 11.9496 1.53 11.8486L9.53 6.84858C9.822 6.66558 10 6.34558 10 6.00058C10 5.65558 9.822 5.33558 9.53 5.15258Z"
          fill="white"
        />
      </svg>
      <span className="text-[14px] leading-[14px] md:text-[15px] md:leading-[18px] font-semibold ml-[21px] ">
        {title}
      </span>
    </div>
  </div>
)

export const PlayButton = ({ onClick }) => (
  <svg
    className="transition-colors duration-150 hover:text-transparent"
    onClick={onClick}
    width="74"
    height="80"
    viewBox="0 0 74 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.44508 1.29354L4.44508 1.29355L4.44931 1.29587L70.9967 37.897C70.9969 37.897 70.997 37.8971 70.9971 37.8972C71.3619 38.0981 71.666 38.3932 71.8779 38.7517C72.0898 39.1103 72.2016 39.5192 72.2016 39.9358C72.2016 40.3523 72.0898 40.7612 71.8779 41.1199C71.666 41.4783 71.3619 41.7734 70.9971 41.9743C70.997 41.9744 70.9969 41.9745 70.9967 41.9746L4.44931 78.5756L4.44892 78.5759C4.10528 78.7651 3.71939 78.8643 3.32712 78.8644H3.32472C2.91156 78.8656 2.50595 78.7538 2.15173 78.5412L2.14511 78.5373C1.79682 78.3319 1.50805 78.0392 1.30726 77.6883C1.10657 77.3375 1.00069 76.9405 1.00004 76.5363V3.33445H1.00005L1.00002 3.32984C0.99817 2.92773 1.10053 2.53199 1.29713 2.18121C1.49334 1.83113 1.77673 1.53775 2.11977 1.32953C2.4698 1.12036 2.86866 1.00682 3.2764 1.0003C3.68495 0.993761 4.08802 1.0949 4.44508 1.29354Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)
