import { graphql, useStaticQuery } from "gatsby"

const useOptions = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        options {
          options {
            header {
              logo {
                ...Image
              }
              whiteLogo {
                ...Image
              }
              primaryCta {
                url
                title
              }
            }
            newsletter {
              image {
                ...Image
              }
              subheading
              heading
              buttonLabel
            }
            footer {
              heading
              socials {
                socialLink
                socialLogo {
                  ...Image
                }
              }
            }
            partner {
              heading
              content
              primaryCta {
                url
                title
              }
              mediaPack {
                downloadText
                file {
                  localFile {
                    publicURL
                  }
                }
              }
            }
            starfieldImages {
              ...Image
            }
            partnerCta {
              heading
              text
              links {
                videoEmbed
                email
                behaviour
                file {
                  localFile {
                    publicURL
                  }
                }
                link {
                  title
                  url
                }
                type
                icon
              }
              ...sectionPartnerCta
            }
            exploreCtaLinks {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  return data?.wp?.options?.options
}

export default useOptions
