import { Link } from "gatsby"
import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"

function MobileNav({ headerNav, setNavOpen }) {
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className="fixed top-0 bottom-0 left-0 right-0 z-40 block w-screen h-screen px-5 pb-12 overflow-y-auto bg-black lg:hidden"
    >
      <div className="mt-24 ">
        {headerNav?.menuItems?.nodes?.map((menuItem, i, arr) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.4,
              delay: (i + 1) / 14,
              ease: "easeIn",
            }}
            key={`mobileNavItem${i}`}
            className="cursor-pointer"
          >
            <DropDown
              link={menuItem?.path}
              label={menuItem?.label}
              childItems={menuItem?.childItems}
            />
          </motion.div>
        ))}

        <div className="mt-6">
          <div className="flex justify-center w-full bg-white rounded-full bg-opacity-20">
            <div className="py-4 font-bold text-white uppercase text-14">
              <Link to="/contact">CONTACT</Link>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center mt-6 space-x-1">
          <svg
            className={``}
            width="35"
            height="34"
            viewBox="0 0 35 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.8338 13.6468C25.1985 13.9645 24.5632 14.0704 23.822 14.1762C24.5632 13.7527 25.0926 13.1174 25.3043 12.2704C24.669 12.6939 23.9279 12.9057 23.0808 13.1174C22.4455 12.4821 21.4926 12.0586 20.5396 12.0586C18.3161 12.0586 16.622 14.1762 17.1514 16.2939C14.2926 16.188 11.7514 14.8115 9.9514 12.6939C8.99846 14.2821 9.52787 16.2939 11.0102 17.3527C10.4808 17.3527 9.9514 17.1409 9.42199 16.9292C9.42199 18.5174 10.5867 19.9998 12.1749 20.4233C11.6455 20.5292 11.1161 20.6351 10.5867 20.5292C11.0102 21.9057 12.2808 22.9645 13.869 22.9645C12.5985 23.9174 10.6926 24.4468 8.89258 24.2351C10.4808 25.188 12.2808 25.8233 14.1867 25.8233C20.6455 25.8233 24.2455 20.4233 24.0338 15.4468C24.7749 15.0233 25.4102 14.388 25.8338 13.6468Z"
              fill="white"
            />
          </svg>

          <svg
            width="35"
            height="34"
            viewBox="0 0 35 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7629 25.4119H9.16293V14.0825H12.7629V25.4119ZM10.9629 12.4942C9.79823 12.4942 8.95117 11.6472 8.95117 10.4825C8.95117 9.31776 9.90411 8.4707 10.9629 8.4707C12.1276 8.4707 12.9747 9.31776 12.9747 10.4825C12.9747 11.6472 12.1276 12.4942 10.9629 12.4942ZM25.8923 25.4119H22.2924V19.2707C22.2924 17.4707 21.5512 16.9413 20.4923 16.9413C19.4335 16.9413 18.3747 17.7883 18.3747 19.3766V25.4119H14.7747V14.0825H18.1629V15.6707C18.4806 14.9295 19.7512 13.7648 21.5512 13.7648C23.5629 13.7648 25.6806 14.9295 25.6806 18.4236V25.4119H25.8923Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </motion.div>
  )
}

export const DropDown = ({ link, label, childItems }) => {
  const [openMenu, setOpenMenu] = useState(false)

  return (
    <div className="w-full">
      <div
        className="flex items-center"
        onClick={() =>
          setOpenMenu(childItems.nodes?.length === 0 ? false : !openMenu)
        }
      >
        <div className="text-white w-full pb-5 text-[1.416rem] font-bold leading-[1.236] uppercase  ">
          <Link to={link || "#"}>{label}</Link>
        </div>
        {childItems.nodes?.length > 1 && (
          <svg
            className={`transform duration-200 ease-in-out mb-5 ${
              openMenu && "rotate-180"
            }`}
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99918 9.23828L8.72585 8.54427L15.2578 2.27359L13.8045 0.763089L7.99911 6.33982L2.19376 0.76309L0.740399 2.27359L7.27236 8.54427L7.99918 9.23828Z"
              fill="white"
            />
          </svg>
        )}
      </div>

      <div
        className={` ${
          openMenu ? "h-[2px] opacity-100" : "h-[0.7px] opacity-30"
        } w-full bg-beige  mb-4 `}
      ></div>

      <AnimatePresence exitBeforeEnter>
        {openMenu && (
          <div>
            {childItems?.nodes?.map((childItem, i, arr) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.4,
                  delay: (i + 1) / 16,
                  ease: "easeIn",
                }}
                key={`mobileChildItem${i}`}
              >
                <Link to={childItem?.path}>
                  {i !== 0 && (
                    <div className="bg-beige h-[2px] w-full mb-2.5"></div>
                  )}
                  <h5 className="text-white uppercase u-h5b">
                    {childItem?.label}
                  </h5>
                  <div className="mb-5 font-medium text-white text-14 opacity-40">
                    {childItem?.description}
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default MobileNav
