import React, { useState } from "react"
import { motion } from "framer-motion"

const AnimatedWebcast = ({ ...other }) => {
  const [onHover, setOnHover] = useState(false)

  return (
    <div
      {...other}
      className="inline-block bg-red"
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div className="p-[10px] relative">
        <motion.div
          initial={{ scale: 1, y: 0 }}
          animate={{ scale: onHover ? 1.5 : 1, y: onHover ? "-2px" : 0 }}
          transition={{ duration: 0.5, ease: "easeIn" }}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 3.00009H7.0675L8.884 0.82005C8.9689 0.71814 9.0098 0.58669 8.9977 0.45462C8.9857 0.32255 8.9217 0.200669 8.8197 0.115799C8.7178 0.0309292 8.5864 -0.00998023 8.4543 0.00206977C8.3222 0.0141198 8.2004 0.0781392 8.1155 0.180049L6.0205 2.69409L4.9 1.20009C4.8606 1.14749 4.8112 1.10329 4.7547 1.06979C4.6982 1.03639 4.6357 1.01439 4.5707 1.00509C4.5057 0.99579 4.4395 0.99939 4.3759 1.01569C4.3123 1.03199 4.2525 1.06069 4.2 1.10009C4.1475 1.13949 4.1032 1.18879 4.0698 1.24529C4.0363 1.30179 4.0143 1.36439 4.005 1.42939C3.9957 1.49439 3.9993 1.56049 4.0156 1.62419C4.0319 1.68779 4.0606 1.74749 4.1 1.80009L5 3.00009H1C0.7348 3.00009 0.4804 3.10539 0.2929 3.29299C0.1054 3.48049 0 3.73479 0 4.00009V11.0001C0 11.2653 0.1054 11.5196 0.2929 11.7072C0.4804 11.8947 0.7348 12.0001 1 12.0001H11C11.2652 12.0001 11.5196 11.8947 11.7071 11.7072C11.8946 11.5196 12 11.2653 12 11.0001V4.00009C12 3.73479 11.8946 3.48049 11.7071 3.29299C11.5196 3.10539 11.2652 3.00009 11 3.00009ZM10.5 10.5001H1.5V4.50009H10.5V10.5001Z"
              fill="white"
            />
          </svg>
        </motion.div>
        <motion.div
          className="absolute inset-0 flex items-center justify-center"
          initial={{ opacity: 0, x: "1px" }}
          animate={{
            opacity: onHover ? 1 : 0,
            scale: onHover ? 1 : 0,
            x: onHover ? "1px" : "-1px",
          }}
          transition={{
            duration: 0.3,
            delay: 0.3,
            scale: { duration: 0.3 },
          }}
        >
          <svg
            width="5"
            height="6"
            viewBox="0 0 5 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 6V0L5 3L0 6Z" fill="white" />
          </svg>
        </motion.div>
      </div>
    </div>
  )
}

export default AnimatedWebcast
