import React from "react"

function DefaultPattern({ opacity = 0.5, container = true, className = "" }) {
  return (
    <div
      className={`${
        container ? "container" : ""
      } absolute inset-0 ${className} pointer-events-none `}
    >
      <div className="grid h-full grid-cols-4 md:grid-cols-10">
        <div
          style={{ opacity: opacity }}
          className="border-l border-dashed border-beige "
        ></div>
        <div
          style={{ opacity: opacity }}
          className="border-l border-dashed border-beige "
        ></div>
        <div
          style={{ opacity: opacity }}
          className="border-l border-dashed border-beige "
        ></div>
        <div
          style={{ opacity: opacity }}
          className="border-l border-r border-dashed border-beige md:border-r-0 "
        ></div>
        <div
          style={{ opacity: opacity }}
          className="hidden border-l border-dashed border-beige md:block "
        ></div>
        <div
          style={{ opacity: opacity }}
          className="hidden border-l border-dashed border-beige md:block "
        ></div>
        <div
          style={{ opacity: opacity }}
          className="hidden border-l border-dashed border-beige md:block "
        ></div>
        <div
          style={{ opacity: opacity }}
          className="hidden border-l border-dashed border-beige md:block "
        ></div>
        <div
          style={{ opacity: opacity }}
          className="hidden border-l border-dashed border-beige md:block "
        ></div>

        <div
          style={{ opacity: opacity }}
          className="hidden border-l border-r border-dashed border-beige md:block "
        ></div>
      </div>
    </div>
  )
}

export default DefaultPattern
