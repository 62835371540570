import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

function Image({ image, objectFit, className, loading, ...other }) {
  return (
    <>
      {image?.localFile?.childImageSharp ? (
        <GatsbyImage
          image={getImage(image?.localFile)}
          alt={image?.altText}
          imgStyle={{ objectFit: objectFit || "contain" }}
          draggable="false"
          loading={loading}
          className={`${className}`}
          {...other}
        />
      ) : (
        <img
          {...other}
          src={image?.localFile?.publicURL}
          alt={image?.altText}
          draggable="false"
          className={`w-full h-full ${className}`}
        />
      )}
    </>
  )
}

export default Image
