import { Link } from "gatsby"
import React from "react"

function HeaderNav({
  subnavHandler,
  setShowSubnav,
  headerNav,
  activeNav,
  showSubnav,
  dark,
}) {
  return (
    <nav className="z-50 flex items-center ">
      {headerNav?.menuItems?.nodes?.map((item, i, arr) => (
        <Link
          onMouseEnter={() => subnavHandler(i)}
          onMouseLeave={() =>
            setShowSubnav(item?.childItems?.nodes.length > 1 ? true : false)
          }
          className={`font-bold md:text-12 xl:text-14 uppercase text-center leading-[1.22] transition-colors duration-200 ease-in-out hover:text-red ${
            activeNav === i && item?.childItems?.nodes.length > 1 && showSubnav
              ? "!text-white"
              : "text-inherit"
          } ${i !== arr.length - 1 && " mr-[25px]"}`}
          key={`menuNavItem${i}`}
          to={item?.path}
        >
          {item?.label}
        </Link>
      ))}
    </nav>
  )
}

export default HeaderNav
