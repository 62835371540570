import { graphql, useStaticQuery } from "gatsby"

const useBlogPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allPosts: allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          categories {
            nodes {
              name
            }
          }
          excerpt
          id
          uri
          title
          featuredImage {
            node {
              ...Image
            }
          }

          date(formatString: "MMMM d YYYY")
        }
      }

      recentPost: allWpPost(
        filter: {}
        limit: 1
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          id
          title
          uri
          date
        }
      }
    }
  `)

  return data
}

export default useBlogPosts
