import { graphql, useStaticQuery } from "gatsby"

const useFooter = () => {
  const data = useStaticQuery(graphql`
    query {
      footerNav: wpMenu(locations: { eq: FOOTER_NAV }) {
        name
        id
        menuItems {
          nodes {
            parentId
            url
            label
            id
            childItems {
              nodes {
                url
                id
                label
                path
              }
            }
            path
          }
        }
      }
    }
  `)

  data.footerNav.menuItems.nodes = data.footerNav.menuItems.nodes.filter(
    n => !n.parentId
  )

  return data
}

export default useFooter
